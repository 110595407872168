import { useState } from "react";
import { DarkGrayMining, grayCategory, DarkGrayElectric, DarkGrayMetal, DarkGraySelolose, DarkGrayConstruction, DarkGrayMicroscope, DarkGrayPetro, DarkEnergy } from "../../icons-base64/icons";

export default function CategoryShortCuts() {
    const [categories, setCategories] = useState([
        {
            name: "معدن",
            icon: DarkGrayMining,
            id: 11
        },
        {
            name: "صنایع الکتریکی",
            icon: DarkGrayElectric,
            id: 10
        },
        {
            name: "فلزات اساسی",
            icon: DarkGrayMetal,
            id: 9
        },
        {
            name: "صنایع سلولزی",
            icon: DarkGraySelolose,
            id: 12
        },
        {
            name: "ساختمان، مسکن، شهرسازی",
            icon: DarkGrayConstruction,
            id: 14
        },
        {
            name: "صنایع شیمیائی و داروئی",
            icon: DarkGrayMicroscope,
            id: 15
        },
        {
            name: "صنایع پتروشیمی",
            icon: DarkGrayPetro,
            id: 16
        },
        {
            name: "سوخت و انرژی",
            icon: DarkEnergy,
            id: 17
        },

    ]);

    return (
        <div className="flex justify-center w-full py-5 px-3">
            <div className="flex flex-wrap justify-center gap-4 max-w-6xl py-2">
                {categories && categories.length > 0 ? (
                    categories.map((c, index) => (

                        <div
                            key={index}
                            className="w-[calc(25%-1rem)] lg:w-[calc(12.5%-1rem)] shadow-md p-1 rounded-md text-xs h-[90px]"
                        >
                            <a href={`/ProductsCategoryDetail?${c.id}`}>
                                <div className="flex flex-col items-center ">
                                    <img src={c.icon} width={27} className="" />
                                    <div className=" flex items-center justify-center h-full text-gray-700 mt-1" >
                                        {c.name}
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))
                ) : (
                    <></>
                )}
            </div>
        </div >
    );
}
