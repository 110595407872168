
import { blackChevonLeft } from "../../icons-base64/icons"
import axios from "axios";
import gateway from "../../api";
import { useEffect, useState } from "react";
export default function BannerGrid(props) {
    const [gridBanners, setGridBanners] = useState(null)


    const fetchGridBanners = () => {

        axios
            .get(`${gateway()}/api/business-info/homeBanners/`)
            .then(res => {

                setGridBanners(res.data)
            })
            .catch(err => { console.error("home banners error : ", err); });
    };

    useEffect(() => {
        fetchGridBanners()
    }, [])
    return <>
        <div className="flex flex-col lg:flex-row mt-8 gap-1">
            <div className="lg:w-1/2 h-[200px] lg:h-[300px] relative overflow-hidden group  cursor-pointer">
                <a href={gridBanners?.Banner1Link ? gridBanners.Banner1Link : null}>
                    {/* Background image with smooth zoom effect */}
                    <div
                        className="absolute inset-0 bg-center bg-cover transition-transform duration-700 ease-in-out group-hover:scale-110"
                        style={{
                            backgroundImage: `url(${gateway()}${gridBanners?.Banner1})`,
                            transformOrigin: 'center',
                        }}
                    ></div>

                    {/* Dark overlay that darkens on hover */}
                    <div className="absolute inset-0 bg-black/30 group-hover:bg-black/50 transition-colors duration-700 ease-in-out z-10 flex items-center justify-center text-white text-lg font-bold">
                        {gridBanners?.Banner1Text}
                    </div>

                    <div className="absolute bottom-[20px] left-[20px] z-20">
                        <button className="text-[9px] text-black flex items-center rounded-md py-1 px-2 border border-solid border-black/10 bg-gray-300">
                            <img src={blackChevonLeft} className="opacity-80 mr-2" width={15} alt="busiknow.com banner" />
                            <div>جزئیات بیشتر</div>
                        </button>
                    </div>
                </a>
            </div>

            <div className="lg:w-1/2 h-[200px] lg:h-[300px] relative overflow-hidden group cursor-pointer">
                {/* Background image with smooth zoom effect */}
                <div
                    className="absolute inset-0 bg-center bg-cover transition-transform duration-700 ease-in-out group-hover:scale-110"
                    style={{
                        backgroundImage: `url(${gateway()}${gridBanners?.Banner2})`,
                        transformOrigin: 'center',
                    }}
                ></div>

                {/* Dark overlay that darkens on hover */}
                <div className="absolute inset-0 bg-black/30 group-hover:bg-black/50 transition-colors duration-700 ease-in-out z-10 flex items-center justify-center text-white text-lg font-bold">
                    {gridBanners?.Banner2Text}
                </div>

                <div className="absolute bottom-[20px] left-[20px] z-20">
                    <button className="text-[9px] text-black flex items-center rounded-md py-1 px-2 border border-solid border-black/10 bg-gray-300">
                        <img src={blackChevonLeft} className="opacity-80 mr-2" width={15} />
                        <div>جزئیات بیشتر</div>
                    </button>
                </div>
            </div>
        </div>

        <div className="flex flex-col lg:flex-row gap-1 mt-1">
            <div className="w-full lg:w-1/3 h-[300px] relative overflow-hidden group cursor-pointer">
                {/* Background image with smooth zoom effect */}
                <div
                    className="absolute inset-0 bg-center bg-cover transition-transform duration-700 ease-in-out group-hover:scale-110"
                    style={{
                        backgroundImage: `url(${gateway()}${gridBanners?.Banner3})`,
                        transformOrigin: 'center',
                    }}
                ></div>

                {/* Dark overlay that darkens on hover */}
                <div className="absolute inset-0 bg-black/30 group-hover:bg-black/50 transition-colors duration-700 ease-in-out z-10 flex items-center justify-center text-white text-lg font-bold">
                    {gridBanners?.Banner3Text}
                </div>

                <div className="absolute bottom-[20px] left-[20px] z-20">
                    <button className="text-[9px] text-black flex items-center rounded-md py-1 px-2 border border-solid border-black/10 bg-gray-300">
                        <img src={blackChevonLeft} className="opacity-80 mr-2" width={15} alt="busiknow.com banner" />
                        <div>جزئیات بیشتر</div>
                    </button>
                </div>
            </div>

            <div className="lg:w-2/3 w-full flex gap-1 flex-col lg:flex-row">
                <div className="lg:w-1/2 h-[200px] lg:h-[300px] relative overflow-hidden group cursor-pointer">
                    {/* Background image with smooth zoom effect */}
                    <div
                        className="absolute inset-0 bg-center bg-cover transition-transform duration-700 ease-in-out group-hover:scale-110"
                        style={{
                            backgroundImage: `url(${gateway()}${gridBanners?.Banner4})`,
                            transformOrigin: 'center',
                        }}
                    ></div>

                    {/* Dark overlay that darkens on hover */}
                    <div className="absolute inset-0 bg-black/30 group-hover:bg-black/50 transition-colors duration-700 ease-in-out z-10 flex items-center justify-center text-white text-lg font-bold">
                        {gridBanners?.Banner4Text}
                    </div>

                    <div className="absolute bottom-[20px] left-[20px] z-20">
                        <button className="text-[9px] text-black flex items-center rounded-md py-1 px-2 border border-solid border-black/10 bg-gray-300">
                            <img src={blackChevonLeft} className="opacity-80 mr-2" width={15} alt="busiknow.com banner" />
                            <div>جزئیات بیشتر</div>
                        </button>
                    </div>
                </div>

                <div className="lg:w-1/2 h-[200px] lg:h-[300px] relative overflow-hidden group cursor-pointer">
                    {/* Background image with smooth zoom effect */}
                    <div
                        className="absolute inset-0 bg-center bg-cover transition-transform duration-700 ease-in-out group-hover:scale-110"
                        style={{
                            backgroundImage: `url(${gateway()}${gridBanners?.Banner5})`,
                            transformOrigin: 'center',
                        }}
                    ></div>

                    {/* Dark overlay that darkens on hover */}
                    <div className="absolute inset-0 bg-black/30 group-hover:bg-black/50 transition-colors duration-700 ease-in-out z-10 flex items-center justify-center text-white text-lg font-bold">
                        {gridBanners?.Banner5Text}
                    </div>

                    <div className="absolute bottom-[20px] left-[20px] z-20">
                        <button className="text-[9px] text-black flex items-center rounded-md py-1 px-2 border border-solid border-black/10 bg-gray-300">
                            <img src={blackChevonLeft} className="opacity-80 mr-2" width={15} alt="busiknow.com banner" />
                            <div>جزئیات بیشتر</div>
                        </button>
                    </div>
                </div>
            </div>
        </div>


    </>
}