import { whiteKey, whiteRegister } from "../../icons-base64/icons";
import { useState } from "react";
import InputError from "../Atoms/InputErrorAT";
import axios from "axios";
import gateway from "../../api";
import { useModal } from "../../Context/AccountModalContext";

export default function AccountForm() {
    const [signUpForm, setSignUpForm] = useState(false)
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [mobile, setMobile] = useState("")
    const [loading, setLoading] = useState(false);
    const [emailErrorFlag, setEmailErrorFlag] = useState(false)
    const [mobileErrorFlag, setMobileErrorFlag] = useState(false)
    const [passwordErrorFlag, setPasswordErrorFlag] = useState(false)
    const [confirmPasswordErrorFlag, setConfirmPasswordErrorFlag] = useState(false)
    const [firstNameErrorFlag, setFirstNameErrorFlag] = useState(false)
    const [lastNameErrorFlag, setLastNameErrorFlag] = useState(false)
    const [passwordMatchingErrorFlag, setPasswordMatchingErrorFlag] = useState(false)

    const { isModalOpen, openModal, closeModal } = useModal(); // Access context values


    function PrepareLoginForm() {
        setLoading(true);
        setEmailErrorFlag(false);
        setPasswordErrorFlag(false);

        if (email === "" || email.length < 5 || !email.includes("@") || !email.includes(".")) {
            setEmailErrorFlag(true);
            setLoading(false);
            return;
        }
        if (password === "" || password.length < 5) {
            setPasswordErrorFlag(true);
            setLoading(false);
            return;
        }

        // API request for login
        axios
            .post(`${gateway()}/api/auth/login/`, new URLSearchParams({ username: email, password: password }))
            .then((res) => {

                setLoading(false);
                alert("خوش آمدید !");
                localStorage.setItem('busiknowToken', res.data.token);
                closeModal()
            })
            .catch((err) => {
                setLoading(false);
                console.error("login error : ", err);
                alert("نام کاربری یا رمز عبور صحیح نمیباشد");
            });
    }
    function PrepareSignupForm() {

        let emailErrorFlag = false;
        let passwordErrorFlag = false;
        let firstNameErrorFlag = false;
        let lastNameErrorFlag = false;
        let confirmPasswordErrorFlag = false;
        let passwordMatchingErrorFlag = false;
        let mobileErrorFlag = false;

        if (email === "" || email.length < 5 || !email.includes("@") || !email.includes(".")) {
            emailErrorFlag = true;
            setEmailErrorFlag(true);
        }

        if (password === "" || password.length < 5) {
            passwordErrorFlag = true;
            setPasswordErrorFlag(true);
        }
        if (mobile === "" || mobile.length !== 11) {
            mobileErrorFlag = true;
            setMobileErrorFlag(true);
        }
        if (confirmPassword === "" || confirmPassword.length < 5) {
            confirmPasswordErrorFlag = true;
            setConfirmPasswordErrorFlag(true);
        }

        if (confirmPassword !== password) {
            passwordMatchingErrorFlag = true;
            setPasswordMatchingErrorFlag(true);
        }

        if (firstName === "") {
            firstNameErrorFlag = true;
            setFirstNameErrorFlag(true);
        }

        if (lastName === "") {
            lastNameErrorFlag = true;
            setLastNameErrorFlag(true);
        }

        if (!emailErrorFlag && !passwordErrorFlag && !confirmPasswordErrorFlag && !passwordMatchingErrorFlag && !firstNameErrorFlag && !lastNameErrorFlag && !mobileErrorFlag) {
            setLoading(true);

            // API request
            axios.post(`${gateway()}/api/auth/register/`, {
                first_name: firstName,
                last_name: lastName,
                email: email,
                username: email,
                password: password,   // main password field
                password2: confirmPassword,
                mobile: mobile  // confirmation password field
            })
                .then((res) => {
                    setLoading(false);
                    alert("درخواست ثبت نام با موفقیت ثبت شد. لطفا ایمیل خود را چک کنید.");
                    setFirstName("");
                    setLastName("");
                    setEmail("");
                    setPassword("");
                    setConfirmPassword("");
                    setMobile("")
                })
                .catch((err) => {
                    setLoading(false);
                    console.error("Signup error: ", err);

                    alert("خطایی رخ داده لطفا ورودی ها را چک کنید");

                });
        }
    }


    return <>
        <div className="flex form-tab bg-gray-500 ">
            <div className="w-1/2 flex items-center justify-center">
                <button className={`text-center text-xs text-white flex items-center justify-center w-full py-2 ${!signUpForm ? "bg-white/20" : ""}`} onClick={() => setSignUpForm(false)}>
                    ورود <img src={whiteKey} width={15} className="mx-1 mt-[3px]" />
                </button>
            </div>
            <div className="w-1/2 flex items-center justify-center">
                <button className={`text-center text-xs text-white flex items-center justify-center w-full py-2 ${signUpForm ? "bg-white/20" : ""}`} onClick={() => setSignUpForm(true)}>
                    ثبت نام <img src={whiteRegister} width={15} className="mx-1 " />
                </button>
            </div>
        </div>
        <div className=" py-3 px-2" dir="rtl">
            {signUpForm ? <>
                <div className="mb-4">
                    <p className="text-gray-700 font-bold text-[10px] mb-1 px-1">نام</p>
                    <input autocomplete="off" type="text" className=" rounded-md border-solid border-gray-300 border text-md w-full py-1 px-2" value={firstName} onChange={(e) => { setFirstName(e.target.value); setFirstNameErrorFlag(false) }} />
                    {firstNameErrorFlag ? <InputError className="text-[9px] lg:text-[10px] mt-2" autocomplete="off" Error message="لطفا نام را وارد کنید" /> : null}

                </div>
                <div className="mb-4">
                    <p className="text-gray-700 font-bold text-[10px] mb-1 px-1"> نام خانوادگی </p>
                    <input autocomplete="off" type="text" className=" rounded-md border-solid border-gray-300 border text-md w-full py-1 px-2" value={lastName} onChange={(e) => { setLastName(e.target.value); setLastNameErrorFlag(false) }} />
                    {lastNameErrorFlag ? <InputError className="text-[9px] lg:text-[10px] mt-2" autocomplete="off" Error message="لطفا نام خانوادگی  را وارد کنید" /> : null}


                </div>
                <div className="mb-4">
                    <p className="text-gray-700 font-bold text-[10px] mb-1 px-1">ایمیل</p>
                    <input autocomplete="off" type="email" className=" rounded-md border-solid border-gray-300 border text-md w-full py-1 px-2" value={email} onChange={(e) => { setEmail(e.target.value); setEmailErrorFlag(false) }} />
                    {emailErrorFlag ? <InputError className="text-[9px] lg:text-[10px] mt-2" autocomplete="off" Error message="لطفا ایمیل را به درستی وارد کنید" /> : null}

                </div>
                <div className="mb-4">
                    <p className="text-gray-700 font-bold text-[10px] mb-1 px-1">شماره موبایل</p>
                    <input autocomplete="off" type="text" maxLength={11} className=" rounded-md border-solid border-gray-300 border text-md w-full py-1 px-2" value={mobile} onChange={(e) => { setMobile(e.target.value); setMobileErrorFlag(false) }} />
                    {mobileErrorFlag ? <InputError className="text-[9px] lg:text-[10px] mt-2" autocomplete="off" Error message="لطفا شماره موبایل را به درستی وارد کنید" /> : null}

                </div>
                <div className="mb-4">
                    <p className="text-gray-700 font-bold text-[10px] mb-1 px-1">رمز عبور</p>
                    <input autocomplete="off" type="password" className=" rounded-md border-solid border-gray-300 border text-md w-full py-1 px-2" value={password} onChange={(e) => { setPassword(e.target.value); setPasswordErrorFlag(false) }} />
                    {passwordErrorFlag ? <InputError className="text-[9px] lg:text-[10px] mt-2" autocomplete="off" Error message="لطفا رمز عبور خود را وارد کنید" /> : null}

                </div> <div className="mb-4">
                    <p className="text-gray-700 font-bold text-[10px] mb-1 px-1">تکرار رمز عبور</p>
                    <input autocomplete="off" type="password" className=" rounded-md border-solid border-gray-300 border text-md w-full py-1 px-2" value={confirmPassword} onChange={(e) => { setConfirmPassword(e.target.value); setConfirmPasswordErrorFlag(false) }} />
                    {confirmPasswordErrorFlag ? <InputError className="text-[9px] lg:text-[10px] mt-2" autocomplete="off" Error message="لطفا تکرار رمز عبور را وارد کنید" /> : null}
                    {passwordMatchingErrorFlag ? <InputError className="text-[9px] lg:text-[10px] mt-2" autocomplete="off" Error message="رمز عبور و تکرار رمز عبور یکسان نیستند" /> : null}

                </div>
            </> : <>
                <div className="mb-4">
                    <p className="text-gray-700 font-bold text-[10px] mb-1 px-1">ایمیل</p>
                    <input autocomplete="off" type="text" className=" rounded-md border-solid border-gray-300 border text-md w-full py-1 px-2" value={email} onChange={(e) => { setEmail(e.target.value); setEmailErrorFlag(false) }} />
                    {emailErrorFlag ? <InputError className="text-[9px] lg:text-[10px] mt-2" autocomplete="off" Error message="لطفا ایمیل را به درستی وارد کنید" /> : null}

                </div>
                <div className="mb-4">
                    <p className="text-gray-700 font-bold text-[10px] mb-1 px-1">رمز عبور</p>
                    <input autocomplete="off" type="password" className=" rounded-md border-solid border-gray-300 border text-md w-full py-1 px-2" value={password} onChange={(e) => { setPassword(e.target.value); setPasswordErrorFlag(false) }} />
                    {passwordErrorFlag ? <InputError className="text-[9px] lg:text-[10px] mt-2" autocomplete="off" Error message="لطفا رمز عبور خود را وارد کنید" /> : null}

                </div>

            </>
            }
            <div className="flex items-center justify-center mb-4" dir="ltr">
                <button className="rounded-md text-center text-xs text-white flex items-center justify-center w-full lg:w-[40%] py-2 bg-gray-500" onClick={signUpForm ? () => PrepareSignupForm() : () => PrepareLoginForm()}>
                    {signUpForm ? <>
                        ثبت نام <img src={whiteRegister} width={15} className="mx-1 " />
                    </> : <>
                        ورود <img src={whiteKey} width={15} className="mx-1 mt-[3px]" />

                    </>}
                </button>
            </div>
            {!signUpForm ? <div className="border-t border-solid border-gray-300 py-3">
                <div className="flex ">
                    <div className=" font-thin text-[9px] lg:text-[10px] text-gray-700">
                        هنوز حساب کاربری ایجاد نکرده اید؟

                    </div>
                    <div className=" font-bold text-[9px] lg:text-[10px] text-blue-500 px-2 !cursor-pointer" onClick={() => setSignUpForm(true)}>
                        ثبت نام کنید

                    </div>
                </div>
                <div className="flex ">

                    <div className=" font-bold text-[9px] lg:text-[10px] text-blue-500 pt-1 !cursor-pointer" >
                        رمز عبورتان را فراموش کرده اید؟

                    </div>
                </div>
            </div> : null}
        </div>
    </>
}