import { blackLeftChevron } from "../../icons-base64/icons";

export default function Pagination(props) {

    return <>
        <div className="flex items-center justify-center gap-5">
            <div className=" flex items-center justify-center ">
                <button className=" rounded-full p-1 border-2 border-solid border-[#262626] shadow-md disabled:cursor-not-allowed disabled:opacity-60" onClick={() => props.nextPage()} disabled={props.pageCount === props.currentPage || props.pageCount === 0}>
                    <img src={blackLeftChevron} width={10} className="rotate-180" alt="next icon" />
                </button>
            </div>
            <div className=" flex items-center justify-center">
                <button className=" rounded-full text-xs p-1 bg-[#262626] text-white shadow-md flex items-center justify-center h-[24px] w-[24px]" disabled>
                    {props.currentPage}
                </button>
            </div>
            <div className=" flex items-center justify-center">
                <button className=" rounded-full p-1 border-2 border-solid border-[#262626] shadow-md disabled:cursor-not-allowed disabled:opacity-60" onClick={() => props.prevPage()} disabled={props.currentPage === 1 || props.pageCount === 0}>
                    <img src={blackLeftChevron} width={10} alt="previous icon" />
                </button>
            </div>


        </div>

    </>
}