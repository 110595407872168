import React, { useEffect, useState, useRef } from "react"
import gateway from "../../api";
import axios from "axios";
import ReactQuill from "react-quill";
export default function AboutUs() {

    const [info, setInfo] = useState(null)
    const [boxHeight, setBoxHeight] = useState(0)
    const [imageHeight, setImageHeight] = useState(0)


    const boxRef = useRef(null);
    const imageRef = useRef(null);

    const fetchAboutUsInfo = () => {
        axios
            .get(`${gateway()}/api/business-info/about/`)
            .then(res => {
                setInfo(res.data)
            })
            .catch(err => { console.error("aboutus info error : ", err); });
    };
    useEffect(() => {

        fetchAboutUsInfo()
    }, [])
    useEffect(() => {

        if (boxRef.current !== null) {
            let modal = boxRef.current
            let height = modal.getBoundingClientRect().height

            setBoxHeight(`${Math.round(height)}px`)

        }
    }, [boxRef])
    useEffect(() => {

        if (imageRef.current !== null) {
            let modal = imageRef.current
            let height = modal.getBoundingClientRect().height

            setImageHeight(`${Math.round(height)}px`)

        }
    }, [imageRef])
    return <>
        <div className=" z-[1] relative pulse min-h-[100px] lg:min-h-[300px]" ref={imageRef}>

            <img className="w-full " src={gateway() + info?.aboutUsImage} />
        </div>
        <div className="flex justify-center" style={{ marginTop: `-${boxHeight}` }}>

            <div className="w-[70%]  lg:w-[50%]   px-4 lg:px-4 py-4 lg:py-10 z-[2]" ref={boxRef} >
                <div className="text-2xl font-bold flex justify-center text-white ">
                    درباره   {info?.name}
                </div>
            </div>
        </div>
        <div className="px-[20px] lg:px-[80px]">

            <html>
                <ReactQuill
                    value={info?.aboutUs}
                    readOnly={true}
                    theme={"snow"}
                    style={{ border: "none" }}
                    modules={{
                        toolbar: false,
                        clipboard: {

                        }
                    }}
                />
            </html>
        </div>
    </>
}