import React, { useEffect, useState } from "react"
import gateway from "../../api";
import axios from "axios";
import ReactQuill from "react-quill";
import { convertToJalali } from "../../Utilities/UtilityMethods";
import { blackBoldCategory, blackCalendar, BlackMindMap, BlackPriceTag, BlackShoppingBag } from "../../icons-base64/icons";

export default function PostDetail(props) {

    const [openCategories, setOpenCategories] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [title, setTitle] = useState('');
    const [keyWords, setKeyWords] = useState('');
    const [summary, setSummary] = useState('');
    const [error, setError] = useState('');
    const [post, setPost] = useState('');
    const [postId, setPostId] = useState('');
    const [mainPic, setMainPic] = useState('');
    const [formHeight, setFormHeight] = useState(250);
    const [bodyHtml, setBodyHtml] = useState(null);
    const [categories, setCategories] = useState([]);
    const [isCategoriesDropOpen, setIsCategoriesDropOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [categoryLoading, setCategoryLoading] = useState(true);
    const [updateDate, setUpdateDate] = useState(null)
    const [category, setCategory] = useState(null)

    const [pictureAlt, setPictureAlt] = useState('')

    const formRef = React.createRef();
    const altRef = React.createRef()

    const pageRenderer = (data) => {
        if (data) {
            setSummary(data.summary);
            setKeyWords(data.keyWords);
            setTitle(data.title);
            setSelectedStatus(data.status);
            setCategory(data.category);
            setBodyHtml(data.body_html);
            setMainPic(`${gateway()}${data.mainPic}`);
            setPictureAlt(data.mainPicAlt);
            setUpdateDate(data.updated_at)

        }
    };
    const fetchPost = (postId) => {
        setIsLoading(true)
        axios
            .get(`${gateway()}/api/posts/${postId}/`)
            .then((res) => {
                setIsLoading(false)

                pageRenderer(res.data);
            })
            .catch((err) => { setIsLoading(false); setError(err); console.error("fetch post error : ", err); });
    };
    const getCategories = () => {
        setCategoryLoading(true)
        axios
            .get(`${gateway()}/api/categories/`)
            .then((res) => {

                setCategoryLoading(false)
                setCategories(res.data);
            })
            .catch((err) => {
                setCategoryLoading(false);
                setError(err);
                console.error("get posts category error : ", err);
            });
    };


    useEffect(() => {
        getCategories()
        if (window) {
            let id = window.location.href?.split("?")[1]
            fetchPost(id)
        }
    }, [window])

    return <>
        <div className="pulse min-h-[100px] lg:min-h-[300px]">
            <img className="w-full h-[60%]" src={mainPic} />
        </div>
        <div className="flex justify-center lg:-mt-[50px] ">

            <div className="w-[70%]  lg:w-[50%]  bg-[#fffceb] px-4 lg:px-4 py-4 lg:py-4 shadow-md" >
                <div className="text-2xl font-bold flex justify-center ">
                    {title}
                </div>

                <div className="text-xs text-gray-500 font-thin flex justify-start text-justify py-3 leading-5">
                    {summary}
                </div>


            </div>
        </div>
        <div className="px-[20px] lg:px-[80px]">
            <div className="flex gap-5 px-8">
                {
                    categories && categories.length > 0 ? <>  <div className="flex justify-start text-[10px] items-center text-gray-600 font-bold gap-1 py-2">

                        <img src={blackBoldCategory} width={16} className="opacity-50" />
                        <div className="rounded-full border border-solid border-gray-500 text-[9px] py-[1px] px-2">
                            {categories.find((e) => e.id === category)?.name}
                        </div>

                    </div>
                    </> : null}
                <div className="flex justify-start text-[10px] items-center text-gray-600 font-bold gap-1 py-2">
                    <img src={blackCalendar} width={14} className="opacity-60" />
                    <div className=" text-[9px] py-[1px] px-2">
                        {convertToJalali(updateDate)}
                    </div>

                </div>
                {
                    keyWords && keyWords.length > 0 ? <>
                        <div className="flex justify-start text-[10px] items-center text-gray-600 font-bold gap-1 py-2">
                            <img src={BlackMindMap} width={15} className="opacity-50" />
                            <div className=" text-[9px] py-[1px] px-2">
                                {keyWords}
                            </div>

                        </div>
                    </> : null}
            </div>
            <html>
                {/* <div dangerouslySetInnerHTML={{ __html: bodyHtml }} /> */}
                <ReactQuill
                    value={bodyHtml}
                    readOnly={true}
                    theme={"snow"}
                    style={{ border: "none" }}
                    modules={{
                        toolbar: false,
                        clipboard: {

                        }
                    }}
                />
            </html>
        </div>
    </>
}