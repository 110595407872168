import React, { useEffect, useState, useRef } from "react"
import gateway from "../../api";
import axios from "axios";
import ReactQuill from "react-quill";
import { BlackEmail, BlackMailBox, BlackPhone, BlackPin } from "../../icons-base64/icons";
export default function ContactUs() {

    const [info, setInfo] = useState(null)
    const [boxHeight, setBoxHeight] = useState(0)
    const [imageHeight, setImageHeight] = useState(0)


    const boxRef = useRef(null);
    const imageRef = useRef(null);

    const fetchContactUsInfo = () => {
        axios
            .get(`${gateway()}/api/business-info/contact/`)
            .then(res => {
                setInfo(res.data)
            })
            .catch(err => { console.error("fetch contact us info error : ", err); });
    };
    useEffect(() => {

        fetchContactUsInfo()
    }, [])
    useEffect(() => {

        if (boxRef.current !== null) {
            let modal = boxRef.current
            let height = modal.getBoundingClientRect().height

            setBoxHeight(`${Math.round(height)}px`)

        }
    }, [boxRef])
    useEffect(() => {

        if (imageRef.current !== null) {
            let modal = imageRef.current
            let height = modal.getBoundingClientRect().height

            setImageHeight(`${Math.round(height)}px`)

        }
    }, [imageRef])
    return <div className=" ">
        <div className=" z-[1] relative pulse min-h-[100px] lg:min-h-[300px]" ref={imageRef}>

            <img className="w-full " src={gateway() + info?.contactUsImage} />
        </div>
        <div className="flex justify-center" style={{ marginTop: `-${boxHeight}` }}>

            <div className="w-[70%]  lg:w-[50%]   px-4 lg:px-4 py-4 lg:py-10 z-[10]" ref={boxRef} >
                <div className="text-2xl font-bold flex justify-center text-white ">
                    ارتباط با ما
                </div>
            </div>
        </div>
        <div className="px-[20px] lg:px-[80px] py-4">
            جهت ارتباط با مجموعه بیزینو میتوانید از اطلاعات زیر استفاده کنید
            <div className="pt-3">
                <div className="flex items-center py-2">
                    <div className="w-1/5  md:1/3 lg:w-1/12 flex justify-center ">
                        <img src={BlackPin} width="30" />
                    </div>
                    <div className="px-3 text-[9px] text-gray-600 text-justify font-thin leading-4 flex-1">

                        <div className="">
                            آدرس
                        </div>

                        <div className="">
                            {info?.address}
                        </div>

                    </div>
                </div>

                <div className="flex items-center py-2">
                    <div className="w-1/5  md:1/3 lg:w-1/12 flex justify-center">
                        <img src={BlackPhone} width="27" />
                    </div>
                    <div className="px-3 text-[10px] text-gray-600 text-justify font-thin leading-4 flex-1">
                        <div className="">
                            شماره تماس
                        </div>
                        <div className="">
                            {info?.tel}
                        </div>
                    </div>
                </div>

                <div className="flex items-center py-2">
                    <div className="w-1/5  md:1/3 lg:w-1/12 flex justify-center">
                        <img src={BlackEmail} width="27" />
                    </div>
                    <div className="px-3 text-[10px] text-gray-600 text-justify font-thin leading-4 flex-1">
                        <div className="">
                            ایمیل
                        </div>
                        <div className="">
                            {info?.email}
                        </div>
                    </div>
                </div>
                <div className="flex items-center py-2">
                    <div className="w-1/5  md:1/3 lg:w-1/12 flex justify-center">
                        <img src={BlackMailBox} width="33" />
                    </div>
                    <div className="px-3 text-[10px] text-gray-600 text-justify font-thin leading-4 flex-1">
                        <div className="">
                            کد پستی
                        </div>
                        <div className="">
                            {info?.postalCode}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
}