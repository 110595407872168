
export default function LoadingAT(props) {

    return <>
        {props.isLoading ? <div className="bg-black/40 absolute top-0 right-0 z-[9999] h-screen w-screen flex items-center justify-center" >
            <img src="/images/pageLoading.svg" className="opacity-90" width={75} alt="loading spinner" />
        </div> : null}
    </>


}