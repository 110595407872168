import { useEffect, useState } from "react";
import gateway from "../../api"
import axios from "axios"

export default function HeroSlider() {
    const [mainBanner, setMainBanner] = useState(null)


    const fetchMainBanner = () => {

        axios
            .get(`${gateway()}/api/business-info/mainBanner/`)
            .then(res => {

                setMainBanner(res.data)
            })
            .catch(err => { console.error("main banner info error : ", err); });
    };

    useEffect(() => {
        fetchMainBanner()
    }, [])
    return <>
        <div className="h-screen bg-blend-darken relative" style={{ backgroundImage: `url(${gateway()}${mainBanner?.mainBanner})` }}>
            <div className="px-2 lg:px-0 bg-black/30 w-full h-screen top-0 right-0 text-[33px] lg:text-[40px] text-white pt-28 lg:pt-32 font-bold text-right lg:pr-14">
                <h1>   {mainBanner?.mainBannerText} </h1>
                <div className="lg:w-1/3 text-justify text-sm md:text-lg mt-3 lg:pr-3 font-normal" dir="rtl">
                    بیزینو بستری امن و حرفه ای جهت ارائه مشاوره و تدوین طرح های سرمایه گذاری در حوزه های مختلف صنعتی تحت نظارت شرکت آرتا بادیز کارو میباشد. این شکت با بهره‌گیری از از کارشناسان مجرب در حوزه های مطالعات بازار ، فنی و مالی تلاش نموده تا در طی سالهای فعالیت خود در این عرصه، چشم انداز واقع‌گرایانه تری از سرمایه گذاری پیش روی سرمایه گذاران قرار دهد.
                </div>
            </div>
        </div>
    </>
}