import { DarkGrayArrow } from "../../icons-base64/icons";
import { useCart } from "../../Context/CartContext";
import CartItem from "../Molecules/CartItem";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { isTokenValid } from "../../Utilities/UtilityApis";
import { useModal } from "../../Context/AccountModalContext";
export default function Cart() {

    const { isCartOpen, closeTheCart, fetchCart, cartDetail, isCartLoading } = useCart();
    const { openModal } = useModal();
    const navigate = useNavigate();
    const divRef = useRef(null)
    const [divHeight, setDivHeight] = useState(null)

    useEffect(() => {

        if (divRef.current !== null) {
            let d = divRef.current
            let divHeight = d.getBoundingClientRect().height
            let desiredArea = Math.round(divHeight - 60)
            setDivHeight(desiredArea)
        }
    })

    useEffect(() => {
        fetchCart()
    }, [])
    const handlePayClick = async () => {
        closeTheCart();
        const tokenIsValid = await isTokenValid();
        if (tokenIsValid) {
            navigate('/checkout');
        } else {
            openModal()
        }
    };
    return <>
        <div className={`bg-black/30 absolute top-0 left-0  h-screen z-[15]  ${isCartOpen ? " w-full" : "w-0"} flex justify-start`} onClick={closeTheCart}></div>
        <div className={`bg-[#262626] absolute dotted-background  z-[1001]  left-0  ${isCartOpen ? "lg:w-[30%] w-[66%]  pr-1" : "w-0 pr-0"} h-screen transition-all duration-200 top-0 `}
            ref={divRef}
        >
            <div style={{ height: divHeight !== null ? `${divHeight}px` : "unset" }} className="overflow-y-auto">
                <div className="flex pt-5 px-3">
                    <div className="flex-1">
                        <img src={DarkGrayArrow} alt="icon" width={27} className="cursor-pointer " onClick={closeTheCart} />
                    </div>
                    <div className="text-[#737373] text-sm font-bold">
                        سبد خرید
                    </div>
                </div>
                <div className="px-2 py-4 relative">{cartDetail !== null && cartDetail.length > 0 ?
                    cartDetail.map((c) => {
                        return <CartItem item={c} getCart={fetchCart} />
                    }) : <div className="text-white text-md h-full w-full items-start pt-5 text-center">سبد خرید خالی میباشد </div>
                }
                    {isCartLoading && <div className="bg-white/20 absolute w-full h-full top-0 right-0 flex items-center justify-center">
                        <img src="/images/loading.svg" width={60} />
                    </div>}
                </div>
            </div>
            <div className=" w-full mt-auto">
                <button disabled={isCartLoading} className="w-full py-3 text-white bg-[#191919]  hover:bg-white
                     hover:text-black border-solid border-black hover:border h-[50px]" onClick={handlePayClick}>
                    پرداخت
                </button>
            </div>
        </div>
    </>
}