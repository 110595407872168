

export default function ImgAT(props) {

    return <>
        <img className={props.className ? props.className : ""} id={props.id ? props.id : ""} src={props.src ? props.src : ""}
            style={{ ...props.style }} width={props.width ? props.width : ""} height={props.height ? props.height : ""} />
    </>
}




