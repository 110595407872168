import { GrayMail, GrayOutlinedInstagram, GrayLinkedIn, GrayPhone, GrayPin, GrayFaceBook, DarkGrayArrow, GrayArrow } from "../../icons-base64/icons";
import { useState, useEffect } from "react";
import gateway from "../../api";
import axios from "axios";
export default function Footer() {
    const [info, setInfo] = useState(null)

    const fetchFooterInfo = () => {
        axios
            .get(`${gateway()}/api/business-info/footer/`)
            .then(res => {
                setInfo(res.data)
            })
            .catch(err => { console.error("footer info error : ", err); });
    };
    useEffect(() => {

        fetchFooterInfo()
    }, [])
    return <>
        <div className="bg-[#191919]" >
            <div className="flex-col flex lg:flex-row px-[20px] lg:px-[80px] items-top
            pb-3
            ">

                <div className="lg:w-1/4  w-full bg-[#262626] px-3 py-6">
                    <div className="w-full flex justify-start"><img src={gateway() + info?.logoLight} width="120" alt="busiknow.com logo" /></div>
                    <div className="text-[10px] text-gray-300 text-justify pt-6 pb-2 font-thin leading-4">
                        {info?.aboutUsSummary}
                    </div>
                    <div className="pt-3">
                        <div className="flex items-center py-2">
                            <div className="w-1/5  md:1/3 lg:w-1/12 flex justify-center ">
                                <img src={GrayPin} width="30" alt="busiknow.com address" />
                            </div>
                            <div className="px-3 text-[9px] text-gray-300 text-justify font-thin leading-4 flex-1">
                                <div className="">
                                    آدرس
                                </div>
                                <div className="">
                                    {info?.address}
                                </div>

                            </div>
                        </div>

                        <div className="flex items-center py-2">
                            <div className="w-1/5  md:1/3 lg:w-1/12 flex justify-center">
                                <img src={GrayPhone} width="27" alt="busiknow.com tel" />
                            </div>
                            <div className="px-3 text-[10px] text-gray-300 text-justify font-thin leading-4 flex-1">
                                <div className="">
                                    شماره تماس
                                </div>
                                <div className="">
                                    {info?.tel}
                                </div>
                            </div>
                        </div>

                        <div className="flex items-center py-2">
                            <div className="w-1/5  md:1/3 lg:w-1/12 flex justify-center">
                                <img src={GrayMail} width="27" alt="busiknow.com email" />
                            </div>
                            <div className="px-3 text-[10px] text-gray-300 text-justify font-thin leading-4 flex-1">
                                <div className="">
                                    ایمیل
                                </div>
                                <div className="">
                                    {info?.email}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="lg:w-3/4 w-full lg:px-[100px] pt-6 ">
                    <div className="border-b border-solid border-white flex items-center flex-row">
                        <div className="w-full lg:w-2/3 flex-col pb-4 px-3 lg:px-0">
                            <div className="text-[10px] text-gray-300 font-thin leading-4 ">
                                ما را دنبال کنید
                            </div>
                            <div className="flex pt-2">
                                <div className="pl-1">
                                    <img src={GrayOutlinedInstagram} width={24} alt="busiknow.com instagram" />
                                </div>
                                <div className="px-1">
                                    <img src={GrayFaceBook} width={24} alt="busiknow.com facebook" />
                                </div>
                                <div className="px-1">
                                    <img src={GrayLinkedIn} width={24} alt="busiknow.com linkedin" />
                                </div>

                            </div>


                        </div>
                        <div className="w-full lg:w-1/3 flex-col pb-4 px-3 lg:px-4">
                            <div className="text-[10px] text-gray-300 font-thin leading-4 ">
                                به خبرنامه بیزینو بپیوندید
                            </div>
                            <div className="flex pt-2">
                                <div className="flex items-center">

                                    <div className=" w-full ">
                                        <input type="text" className="bg-[#262626] rounded-r-md p-1" />
                                    </div>
                                    <button className="p-1 border border-dashed border-[#ebebeb] rounded-l-md">
                                        <img src={GrayArrow} width="21" />
                                    </button>
                                </div>
                            </div>


                        </div>
                    </div>
                    <div className="flex  pt-4">
                        <div className="w-full lg:w-1/3">
                            <div className="font-bold text-sm text-white">
                                لینک های مفید
                            </div>
                        </div>
                        <div className="w-full lg:w-1/3 flex justify-center">
                            <div className="font-bold text-sm text-white">
                                خدمات ما
                            </div>
                        </div>
                        <div className="w-full lg:w-1/3 flex justify-end">
                            <div className="font-bold text-sm text-white">
                                <a referrerpolicy='origin' target='_blank' href='https://trustseal.enamad.ir/?id=541199&Code=kAo4eO4pS0k8kPdXbwjHxhKPGqAPpgQ5'><img referrerpolicy='origin' src='https://trustseal.enamad.ir/logo.aspx?id=541199&Code=kAo4eO4pS0k8kPdXbwjHxhKPGqAPpgQ5' alt='' className="cursor-pointer" code='kAo4eO4pS0k8kPdXbwjHxhKPGqAPpgQ5' /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copyright border-t border-solid border-orange-500 flex justify-center text-xs text-gray-300 py-2">
                کلیه حقوق این وبسایت متعلق به شرکت آرتا بادیز  کارو می‌باشد
            </div>
        </div>

    </>
}