import axios from "axios";
import gateway from "../api";


export function isTokenValid() {
    const token = localStorage.getItem('busiknowToken') || "";
    if (!token) return Promise.resolve(false);

    return axios.get(`${gateway()}/api/auth/user/validate-token/`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.data.valid;
        })
        .catch(() => {
            return false;
        });
}