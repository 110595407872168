import { useState, useEffect, useRef } from "react";

export default function PopOverDropAt(props) {
    const isOpen = props.isOpen;
    const popoverRef = useRef(null);

    useEffect(() => {
        // Function to close popover on outside click
        const handleClickOutside = (event) => {
            if (popoverRef.current && !popoverRef.current.contains(event.target)) {
                props.onTriggerClick(false);
            }
        };

        // Bind the event listener if the popover is open
        if (isOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        // Cleanup event listener on unmount or if the open state changes
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpen]);

    return (
        <div
            ref={popoverRef}
            style={{ cursor: "pointer" }}
            className={`relative ${props.containerClassName || ""} ${isOpen ? "border-t border-r border-l rounded-t-md" : "border rounded"} w-full`}
        >
            <div className="p-1 text-xs" onClick={() => props.onTriggerClick(!isOpen)}>
                {props.DropTitle}
            </div>
            {isOpen && (
                <div className="shadow-sm bg-white border-b border-x border-blue-200 rounded-b-lg break-words absolute z-[100] top-100 w-full max-h-[200px] overflow-y-auto">
                    <div className="p-1">{props.children}</div>
                </div>
            )}
        </div>
    );
}
