import { blackArticleOutlined, blackIdea, blackIdeaOutlined, whiteArticleOutlined, whiteIdeaOutlined } from "../../icons-base64/icons";

export default function DesktopMenu(props) {


    return <>
        <div className={`flex items-center justify-start gap-5 text-sm ${props.isBannerPage ? "text-white" : "text-black"}`}>
            <a href="/AllPosts" className="flex items-center">
                <img src={props.isBannerPage ? whiteArticleOutlined : blackArticleOutlined} width={25} className="ml-1" alt="articles icon" />  مقالات
            </a>
            <a href="/AllProducts" className="flex items-center">
                <img src={props.isBannerPage ? whiteIdeaOutlined : blackIdeaOutlined} width={25} className="ml-1" alt="articles icon" />  محصولات
            </a>

            <a href="/AboutUs">
                درباره ما
            </a>
            <a href="/ContactUs">
                ارتباط با ما
            </a>
            <a href="/Policies">
                قوانین
            </a>
        </div>
    </>

}