

import { useEffect, useState, useRef } from 'react';
export default function ModalMOL(props) {
    const [openModal, setOpenModal] = useState(true)
    const [modalTop, setModalTop] = useState("top-0")
    const [modalRight, setModalRight] = useState("right-0")

    const modalRef = useRef(null);

    useEffect(() => {

        setOpenModal(props.show)
    }, [props.show])


    useEffect(() => {

        if (modalRef.current !== null && window) {
            let modal = modalRef.current
            let width = modal.getBoundingClientRect().width
            let height = modal.getBoundingClientRect().height
            let windowWidth = window.innerWidth
            let windowHeight = window.innerHeight

            let right = (windowWidth / 2) - (width / 2)
            let top = (windowHeight / 2) - (height / 2)

            setModalRight(`${Math.round(right)}px`)
            setModalTop(`${props.position === "center" ? Math.round(top) : props.position === "top" ? Math.round(top - (windowHeight / 20)) : Math.round(top + (windowHeight / 20))}px`)

        }
    }, [modalRef, window])

    useEffect(() => {
        if (openModal === false) {
            props.modalClosed()
        }
    }, [openModal])
    return <>
        {openModal ? <>
            <div className='w-full h-screen z-[99] bg-black/20 absolute top-0 right-0' onClick={(e) => { e.stopPropagation(); setOpenModal(false) }}></div>
            <div className={`absolute  ${props.size === "sm" ? "w-[70%] lg:w-[30%]" : props.size === "md" ? "w-[80%] lg:w-[50%]" : "w-[90%] lg:w-[70%]"}
             z-[100] ${props.modalClassName ? props.modalClassName : ""}`} style={{ top: modalTop, right: modalRight }} ref={modalRef}>
                <div className={props.modalBodyClassName ? props.modalBodyClassName : ""}>
                    {props.children ? props.children : null}
                </div>

            </div> </> : null
        }
    </>

    // <Modal
    //     show={openModal}
    //     onHide={() => { setOpenModal(false); props.hide() }}
    //     size={props.size}
    //     aria-labelledby="contained-modal-title-vcenter"
    //     centered
    //     className='text-right'
    //     style={{ direction: "rtl" }}
    // >
    //     <Modal.Header className={props.headerClassName}>
    //         <p className='my-1 text-[16px] w-full'>
    //             {props.heading}
    //         </p>
    //     </Modal.Header>
    //     <Modal.Body>
    //         {props.children}
    //     </Modal.Body>

    // </Modal>


}