import jalaali from 'jalaali-js';

export const convertToJalali = (gregorianDate) => {
    const date = new Date(gregorianDate);
    const jalaliDate = jalaali.toJalaali(date);

    return `${jalaliDate.jy}/${jalaliDate.jm}/${jalaliDate.jd}`;
};



export function ThosandsSeperator(number) {
    if (number !== undefined) {
        let num = number.toString().replaceAll(",", "")
        let seperated = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return seperated
    } else {
        return ""
    }
}