export default function SearchResultItem(props) {

    return <>
        <div className="flex rounded-md shadow-md border border-solid border-gray-300 items-center h-[50px] mb-1" style={{ cursor: "pointer" }}>
            <div className="w-1/4 border-2 border-solid border-white h-full bg-cover bg-no-repeat rounded-r-md" style={{
                backgroundImage: `url(${props.pic})`,
            }}>
            </div>
            <div className="flex w-3/4 text-xs p-1 justify-center">
                {props.title}
            </div>
        </div>
    </>
}