import { useState, useEffect } from "react";
import { whiteClose, whiteUser, whiteMobileMenu, WhiteShoppingBag, BlackShoppingBag, BlackMobileMenu } from "../../icons-base64/icons"
import MenuContent from "../Molecules/MenuContent";
import AccountContent from "../Molecules/AccountContent";
import Cart from "./Cart";
import gateway from "../../api";
import axios from "axios";
import { useLocation } from 'react-router-dom';
import Search from "./Search"
import { useCart } from "../../Context/CartContext";

export default function MobileHeader(props) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isAccountOpen, setIsAccountOpen] = useState(false);
    const [info, setInfo] = useState(null)

    const location = useLocation();

    const isHomePage = location.pathname === '/';
    const isAboutUsPage = location.pathname === '/AboutUs';
    const isContactUsPage = location.pathname === '/ContactUs';
    const isPoliciesPage = location.pathname === '/Policies';
    const isPostPage = location.pathname === '/PostDetail';



    var isBannerPage = false;

    if (isPoliciesPage || isContactUsPage || isAboutUsPage || isHomePage || isPostPage) {
        isBannerPage = true
    }


    function OpenAccount() {
        if (isAccountOpen === false) {
            setIsMenuOpen(false);
            setTimeout(() => {
                setIsAccountOpen(true)
            }, 200);
        } else {
            setIsAccountOpen(false)
        }
    }
    function OpenMenu() {
        if (isMenuOpen === false) {
            setIsAccountOpen(false);
            setTimeout(() => {
                setIsMenuOpen(true)
            }, 200);
        } else {
            setIsMenuOpen(false)
        }
    }
    function openAccountModal() {
        setIsAccountOpen(false)
        setTimeout(() => {
            props.openAccountModal()
        }, 200);
    }
    const fetchHeaderInfo = () => {
        axios
            .get(`${gateway()}/api/business-info/header/`)
            .then(res => {

                setInfo(res.data)
            })
            .catch(err => { console.error("header info error : ", err); });
    };


    const { isCartOpen, openTheCart, closeTheCart } = useCart()


    useEffect(() => {

        fetchHeaderInfo()
    }, [])
    return <div className="lg:hidden">

        <div className="flex lg:hidden absolute w-full top-0 right-0  secondary-color
         items-center px-4 py-1 z-[3]" >
            <div className={`w-1/3 flex justify-start ${isMenuOpen ? "pr-[5px]" : "pr-0"}`} onClick={() => OpenMenu()}><img src={isBannerPage ? whiteMobileMenu : BlackMobileMenu} width={20} alt="menu icon" /></div>
            <div className="w-1/3 flex justify-center"><a href="/">{info ? <img src={`${isBannerPage ? gateway() + info.logoLight : gateway() + info.logoDark}`} width="70" alt="busiknow.com logo" /> : null}</a></div>
            <div className="w-1/3 flex justify-end"><img src={isBannerPage ? WhiteShoppingBag : BlackShoppingBag} width={24} onClick={openTheCart} alt="shopping bag icon " /></div>
        </div>

        <div className={`bg-black/30 absolute  h-screen z-[3]  ${isMenuOpen ? " w-full" : "w-0"} flex justify-end`} onClick={() => setIsMenuOpen(false)}></div>
        <div className={`dotted-background absolute  z-[1001]  right-0  ${isMenuOpen ? "w-[66%] pr-1" : "w-0 pr-0"} h-screen transition-all duration-200 top-0   pt-6`}>
            <div className={`${isMenuOpen ? "px-4 " : "hidden"} mb-3`}>  <Search /></div>
            <AccountContent expanded={true} openAccountModal={() => { openAccountModal(); setIsMenuOpen(false) }} />
            <MenuContent expanded={true} />
        </div>

        <div className={`bg-black/30 absolute  h-screen z-[3]  ${isAccountOpen ? " w-full" : "w-0"} flex justify-end`} onClick={closeTheCart}></div>

        <Cart isOpen={isAccountOpen} closeCart={closeTheCart} />
    </div>
}