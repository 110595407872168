

export default function DropItemAT(props) {

    return <>
        <span className="text-xs mr-1 text-gray-600 py-1 px-3 flex flex-1 shadow-sm rounded-lg border border-solid border-gray-300 mt-2 bg-gradient-to-b from-white to-blue-50 items-center"
            style={{ cursor: "pointer" }}
        >
            {props.children}
        </span>
    </>
}