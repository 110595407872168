import gateway from "../../api";
import { whiteplus, whiteTrash } from "../../icons-base64/icons";
import { ThosandsSeperator } from "../../Utilities/UtilityMethods";
import { useState } from "react";
import { useCart } from "../../Context/CartContext";
export default function CheckoutItem(props) {
    let item = props.item
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)

    const { DeleteCartItem } = useCart();
    return <>
        <div className={`flex flex-col lg:flex-row pb-3 ${!props.last && "border-b border-solid border-gray-300"}`}>
            <div className="flex gap-2 items-center flex-1">
                <div className="">
                    <img src={`${gateway()}${item.product_mainPic}`} className="rounded-md w-[60px] lg:w-[81px] border-2 border-solid border-white shadow-md" />
                </div>
                <div className="text-xs  text-black/80 text-right font-bold">
                    {item.product_title}
                </div>
            </div>
            <div className="flex gap-2 items-center justify-end">
                <div className="">
                    <div className="text-sm mb-1  text-black">
                        {ThosandsSeperator(item.product_price)} تومان
                    </div>                </div>
                <div className="text-sm  text-black/80 text-right font-bold">
                    <button onClick={() => DeleteCartItem(item.product_id)} className="bg-red-500 p-[9px] rounded-full shadow-md border-2 border-solid border-white ">
                        <img src={whiteTrash} width={17} />
                    </button>
                </div>
            </div>
        </div>
    </>
}