import BannerGrid from "../Organisms/BannerGridORG";
import CategoryShortCuts from "../Organisms/CategoryShortCuts";
import HeroSlider from "../Organisms/HeroSlider";
import PostsSwiper from "../Organisms/PostsSwiper";
import ProductsSwiper from "../Organisms/ProductsSwiper";

export default function Home() {

    return <>
        <HeroSlider />
        <div className="px-[20px] lg:px-[80px]">

            <CategoryShortCuts />
            <div className=""><ProductsSwiper /></div>
            <BannerGrid />
            <div className=""><PostsSwiper /></div>

        </div>
    </>
}