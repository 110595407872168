import { useState } from "react"
import PaymentPending from "./PaymentPending"
import PaymentSucces from "./PaymentSucces"
import PaymentFail from "./PaymentFail"

export default function PaymentResult() {
    const [resultComponent, setResultComponent] = useState("pending")
    return <>
        <div className="px-[20px] lg:px-[80px] pt-28">
            {resultComponent === "pending" ? <PaymentPending /> : resultComponent === "success" ? <PaymentSucces /> : resultComponent === "failed" ? <PaymentFail /> : null}
        </div>
    </>
}