
import { useEffect, useState } from "react";

export default function TreeStructure(props) {
    const [treeData, setTreeData] = useState([]);

    const generateTreeData = () => {
        setTreeData(buildTree(props.data));
    }

    const buildTree = (categories) => {
        let map = {};
        let roots = [];

        categories.forEach(category => {
            map[category.id] = { ...category, children: [] };
        });

        categories.forEach(category => {
            if (category.parent !== null) {
                map[category.parent].children.push(map[category.id]);
            } else {
                roots.push(map[category.id]);
            }
        });

        return roots;
    }

    useEffect(() => {
        if (props.data && props.data.length > 0) {
            generateTreeData();
        }
    }, [props.data]);

    return (
        <div>
            {treeData.map(node => (
                <TreeNode
                    key={node.id}
                    node={node}
                    level={0}
                    selectedCategories={props.selectedCategories}
                    toggleCategorySelection={props.toggleCategorySelection}
                />
            ))}
        </div>
    );
}
const TreeNode = ({ node, level, selectedCategories, toggleCategorySelection }) => {
    const isSelected = selectedCategories.includes(node.id);

    return (
        <div className={`mt-2 ${node.children && node.children.length > 0 ? "" : "pr-2"}}`} style={{ marginRight: `${level * 8}px` }}>
            <div className="flex align-center">
                {node.parent !== null && (
                    <div className="relative w-[15px] ml-2">
                        <div className="rounded-sm h-[50%] absolute top-0 border-b-2 border-r-2 border-dashed border-gray-400 w-[10px]"></div>
                    </div>
                )}
                <span className="text-xs mr-1 text-gray-600 py-1 px-3 flex flex-1 shadow-sm rounded-lg border border-solid border-gray-300 mt-2 bg-gradient-to-b from-white to-blue-50 items-center"
                    style={{ cursor: "pointer" }}
                >
                    <input
                        type="checkbox"
                        checked={isSelected}
                        onChange={() => toggleCategorySelection(node.id)}
                        className="mx-1"
                    />
                    {node.name}
                </span>
            </div>
            {node.children && node.children.length > 0 && (
                <div>
                    {node.children.map(child => (
                        <TreeNode
                            key={child.id}
                            node={child}
                            level={level + 1}
                            selectedCategories={selectedCategories}
                            toggleCategorySelection={toggleCategorySelection}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};
