import { useEffect, useState } from 'react';
import { ThosandsSeperator } from "../../Utilities/UtilityMethods";


export default function ProductsListItem(props) {

    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {

        if (window) {
            if (window.innerWidth < 550) {
                setIsMobile(true)
            }
        }
    }, [])
    return <>
        <div className="atomic-card-primary flex  text-right  flex-col-reverse" >
            <div className="w-full  px-3  w items-center flex-col-reverse">
                <div className="flex-1 text-right flex flex-col items-start w-full my-2">
                    <div className={`w-full atomic-heading-primary text-[11px] lg:text-[15px] text-black h-[40px] lg:h-[55px] text-center`}>
                        {props.item.title ? props.item.title.slice(0, 35) + (props.item.title.length > 35 ? " ..." : "") : ""}
                    </div>
                    {/* <div className="w-full text-justify	items-start atomic-subHeading-primary text-[11px] my-2 h-[50px] lg:h-[90px]" dir="rtl">
                        {props.item.summary ? isMobile ? props.item.summary.slice(0, 50) + (props.item.summary.length > 50 ? " ..." : "") : props.item.summary.slice(0, 120) + (props.item.summary.length > 120 ? " ..." : "") : ""}
                    </div> */}

                    <div className="flex pt-2 border-t border-solid border-black/20 w-full">
                        <div className="flex justify-center gap-4 items-center w-full">
                            <div className="font-bold ">
                                {ThosandsSeperator(props.item?.price)}
                            </div>
                            <div className="text-[9px] font-thin">تومان</div>

                        </div>

                    </div>
                </div>
            </div>
            <div className=" w-full   p-0">
                <div
                    className="w-full aspect-square bg-cover bg-center shadow-inner"
                    style={{ backgroundImage: `url(${props.item.thumbnail})` }}
                ></div>
            </div>


        </div>
    </>
}