
import ImgAT from "../Atoms/ImgAT";


export default function ImageWithSubtitleMOL(props) {

    return <div className={`py-2 mb-3 pl-[16px]   ${props.expanded ? "flex" : ""}`}>

        {props.expanded ?
            <div className="ml-auto mr-2 flex gap-3">
                <div >
                    <ImgAT className={`${props.expanded ? "" : ""} ${props.iconClassName ? props.iconClassName : ""}`} width={props.iconWidth ? props.iconWidth : "15px"} src={props.iconSrc ? props.iconSrc : ""}
                        alt="icon" />

                </div>
                <div className={props.headingClassName ? props.headingClassName : ""} >

                    {props.label ? props.label : ""}
                </div>
            </div>
            : null}


    </div>
}