import axios from "axios";
import gateway from "../../api";
import { useEffect, useState } from "react";

export default function AccountActivation() {
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState("");
    const [hasActivated, setHasActivated] = useState(false); // Track activation status

    const VerifyRegistration = (token) => {
        setLoading(true);
        axios
            .post(`${gateway()}/api/auth/activate/`, new URLSearchParams({ token: token }))
            .then((res) => {
                setResult("فعال سازی با موفقیت انجام شد");
                setLoading(false);
                setHasActivated(true); // Mark activation as completed
            })
            .catch((err) => {
                if (err.response?.data?.message) {
                    setResult(err.response?.data?.message);
                } else {
                    setResult("خطا در فعال سازی");
                }
                console.error("email activation error : ", err);
                setLoading(false);
            });
    };

    useEffect(() => {
        if (!hasActivated) { // Only attempt activation if it hasn't been done yet
            const token = window.location.href?.split("token=")[1];
            if (token) VerifyRegistration(token);
        }
    }, [hasActivated]); // hasActivated dependency ensures only one request

    return (
        <div className="min-h-screen flex justify-center items-center">
            {loading ? "در حال پردازش..." : result}
        </div>
    );
}
